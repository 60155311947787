import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId, getTicketTypeNumberOfAvailableTickets } from "ticketino-api-client";

import Header from "./components/Header";
import "../css/style.css";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

const RecommendedEvents = () => {

    const [selctedCalendarEvent, setSelctedCalendarEvent] = useState([]);
    const [masterEvent, setMasterEvent] = useState([]);
    const [order, setOrder] = useState({});
    const [recommendedWeinschiffeEvent, setRecommendedWeinschiffeEvent] = useState([]);
    const [masterEventTicketTypes, setMasterEventTicketTypes] = useState([]);
    const [recommendedWeinschiffeEventTicketTypes, setRecommendedWeinschiffeEventTicketTypes] = useState([]);
    const [selectedTicketTypes] = useState([]);
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [moreInfo, setMoreInfo] = useState({});
    const [loading, setLoading] = useState(true);

    const masterEventId = sessionStorage.getItem("masterEventId");;
    const recommendedWeinschiffeEventId = sessionStorage.getItem("recommendedWeinschiffeEventId");;
    const selctedCalendarEventId = sessionStorage.getItem("selctedCalendarEventId");
    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        const setupHeadersAndLoadData = async () => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            api.defaults.headers.common['Authorization'] = "Bearer " + token;

            if (orderId) {
                await loadEvent(masterEventId, recommendedWeinschiffeEventId, orderId)
            }
        }
        setupHeadersAndLoadData();
    }, [orderId, language]); //gets called when an order is started

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };


    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const goBack = async () => {
        let ticketsInOrder = [];

        ticketsInOrder = order.tickets.map(ticket => ticketsInOrder.push(ticket.id));

        // deletes all the existing tickets inside the order
        removeTicketFromBasket(ticketsInOrder);

        navigate(-1);
    }

    const removeTicketFromBasket = (ticketIds) => {
        let body = {
            "TicketsToRemove": ticketIds
        }

        axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { headers: { Accept: "application/json" }, data: body }).then((res) => {
        }).catch((error) => {
            console.error(error.response.data);
        });
    }

    const loadEvent = async (masterEventId, recommendedWeinschiffeEventId, orderId) => {
        try {

            if (!(orderId > 0)) {
                navigate(-1);
            }

            let selctedCalendarEvent = await getEventById(selctedCalendarEventId);

            setSelctedCalendarEvent(selctedCalendarEvent);
            // TicketType
            let ticketTypesOfCalendarEvent = await getTicketTypesByEventId(selctedCalendarEventId);

            let calendarEventTicketTypeIds = ticketTypesOfCalendarEvent.map(tt => tt.id);

            // Order
            const order = await getOrderByOrderId(orderId);
            setOrder(order);
            console.log("orderId");
            console.log(orderId);
            console.log("order");
            console.log(order);

            const bookedTickets = order.tickets;

            if (bookedTickets.length == 0 || !bookedTickets.some(t => calendarEventTicketTypeIds.includes(t.ticketTypeId))) {
                navigate(-1);
                return;
            }

            if (selctedCalendarEvent?.start) {

                const calendarEventDate = new Date(selctedCalendarEvent.start);

                setLoading(true);
                // Event
                const masterEvent = await getEventById(masterEventId);
                const masterEventInfos = await getEventInfosByEventId(masterEventId);

                // sorting it according to the language
                const masterEventInfo = masterEventInfos.eventInfos.find(info => info.languageId == languageId);

                // setting the eventInfo object in the info attribute of event
                const updatedMasterEventInfo = { ...masterEvent, info: masterEventInfo };

                // TicketType
                let masterEventTicketTypes = await getTicketTypesByEventId(masterEventId);

                //filter - ticket type's start date with calendar event start date should be same

                masterEventTicketTypes = masterEventTicketTypes.filter(ticketType =>
                    areDatesEqual(calendarEventDate, new Date(ticketType.start))
                );


                const updatedMasterEventTicketTypes = await Promise.all(
                    masterEventTicketTypes.map(async (ticketType) => {

                        if (!areDatesEqual(calendarEventDate, new Date(ticketType.start))) {
                            return null;
                        }

                        const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);
                        // sorting it according to the language

                        const ticketTypeInfo = info.find(info => info.languageId == languageId);

                        const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId);

                        // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                        return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability }
                    })
                )

                // setting the values in the end
                setMasterEvent(updatedMasterEventInfo);
                setMasterEventTicketTypes(updatedMasterEventTicketTypes);

                // Event
                const recommendedWeinschiffeEvent = await getEventById(recommendedWeinschiffeEventId);
                const recommendedWeinschiffeEventInfos = await getEventInfosByEventId(recommendedWeinschiffeEventId);

                // sorting it according to the language
                const recommendedWeinschiffeEventInfo = recommendedWeinschiffeEventInfos.eventInfos.find(info => info.languageId == languageId);

                // setting the eventInfo object in the info attribute of event
                const updatedRecommendedWeinschiffeEvent = { ...recommendedWeinschiffeEvent, info: recommendedWeinschiffeEventInfo };

                // TicketType
                let recommendedWeinschiffeEventTicketTypes = await getTicketTypesByEventId(recommendedWeinschiffeEventId);


                recommendedWeinschiffeEventTicketTypes = recommendedWeinschiffeEventTicketTypes.filter(ticketType =>
                    areDatesEqual(calendarEventDate, new Date(ticketType.start))
                );

                const updatedRecommendedWeinschiffeEventTicketTypes = await Promise.all(
                    recommendedWeinschiffeEventTicketTypes.map(async (ticketType) => {

                        if (!areDatesEqual(calendarEventDate, new Date(ticketType.start))) {
                            return null;
                        }

                        const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                        // sorting it according to the language
                        const ticketTypeInfo = info.find(info => info.languageId == languageId);

                        const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId);

                        // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                        return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability }
                    })
                )


                // setting the values in the end
                setRecommendedWeinschiffeEvent(updatedRecommendedWeinschiffeEvent);
                setRecommendedWeinschiffeEventTicketTypes(updatedRecommendedWeinschiffeEventTicketTypes);
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
            console.error(error);
            navigate(-1);
        }
    }

    const areDatesEqual = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                navigate(`/${language}/address`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onSubmit = async () => {
        await addTicketToBasket();
    };

    const onTicketTypeChange = (e, ticketTypeId) => {
        const obj = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
        };

        const existingTicketType = selectedTicketTypes.find(
            (t) => t.ticketTypeId === ticketTypeId
        );

        if (existingTicketType) {
            existingTicketType.quantity = e.target.value;
        } else {
            selectedTicketTypes.push(obj);
        }

        // calcualate quantity and validate
        let quantity = 0;

        selectedTicketTypes.map(s => {
            quantity += s.quantity * 1;
        });

    };

    const masterEventTextContainer = () => {
        let eventStart = "";

        if (selctedCalendarEvent?.start) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            eventStart = new Date(selctedCalendarEvent?.start).toLocaleDateString(`de`, options);
        }

        return (
            <div className="fs-6 text-container mb-3 row">
                <div className="col-6 text-start">{resources?.Masterclasses}</div>
                <div className="col-6 text-end fs-6">{eventStart}</div>
            </div>
        )
    }

    const recommendedWeinschiffeEventTextContainer = () => {

        if (recommendedWeinschiffeEventTicketTypes?.length > 0) {
            let eventStart = "";

            if (selctedCalendarEvent?.start) {
                const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                eventStart = new Date(selctedCalendarEvent?.start).toLocaleDateString(`de`, options);
            }

            return (
                <div className="fs-6 text-container mb-3 row">
                    <div className="col-6 text-start">{resources?.MoreEvents}</div>
                    <div className="col-6 text-end fs-6">{eventStart}</div>
                </div>
            )
        }
    }

    const mapMasterEventTicketTypes = () => {
        let masterEventTicketTypesSorted = [];

        if (masterEventTicketTypes && masterEventTicketTypes.length > 0) {
            masterEventTicketTypesSorted = [...masterEventTicketTypes].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        return (
            masterEventTicketTypes &&
            masterEventTicketTypes.length > 0 &&
            masterEventTicketTypesSorted.map((tt, index) => (
                <div key={index}>
                    {
                        // tt.availability?.status === 0 &&
                        (
                            <div className={"row pt-2 pb-2" + (tt.availability?.availableTickets > 0 ? "" : " ticket-disabled")}>
                                <div className="col-md-8 col-6">
                                    <label className="fs-6">
                                        {tt.info?.name}
                                    </label>
                                    <div className="description">
                                        <span onClick={() => setMoreInfo({ [tt.id]: !moreInfo[tt.id] })}>{resources.OtherInfos} <i className={"bi bi-chevron-" + (moreInfo[tt.id] ? "down" : "up")}></i></span><br></br>
                                        {moreInfo[tt.id] && tt.info?.description}
                                    </div>
                                </div>
                                <div className="col-md-2 col-3 text-center">
                                    {(index >= 0) && <label>
                                        {tt.currency} {tt.price}.-
                                    </label>}
                                </div>
                                <div className="col-md-2 col-3">
                                    <select
                                        className="form-select"
                                        onChange={(e) => onTicketTypeChange(e, tt.id)}
                                    >
                                        {Array.from(
                                            Array(
                                                tt.availability?.availableTickets + 1
                                            ),
                                            (e, i) => {
                                                return (
                                                    <option key={i} value={i}>
                                                        {i}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </div>
                            </div>
                        )}
                </div>
            ))
        );
    };

    const mapRecommendedWeinschiffeEventTicketTypes = () => {
        let recommendedWeinschiffeEventTicketTypesSorted = [];

        if (recommendedWeinschiffeEventTicketTypes && recommendedWeinschiffeEventTicketTypes.length > 0) {
            recommendedWeinschiffeEventTicketTypesSorted = [...recommendedWeinschiffeEventTicketTypes].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        return (
            recommendedWeinschiffeEventTicketTypes &&
            recommendedWeinschiffeEventTicketTypes.length > 0 &&
            recommendedWeinschiffeEventTicketTypesSorted.map((tt, index) => (
                <div key={index}>
                    {
                        // tt.availability?.status === 0 &&
                        (
                            <div className={"row pt-2 pb-2" + (tt.availability?.availableTickets > 0 ? "" : " ticket-disabled")}>
                                <div className="col-md-8 col-6">
                                    <label className="fs-6">
                                        {tt.info?.name}
                                    </label>
                                    <div className="description">
                                        <span onClick={() => setMoreInfo({ [tt.id]: !moreInfo[tt.id] })}>{resources.OtherInfos} <i class={"bi bi-chevron-" + (moreInfo[tt.id] ? "down" : "up")}></i></span><br></br>
                                        {moreInfo[tt.id] && tt.info?.description}
                                    </div>
                                </div>
                                <div className="col-md-2 col-3 text-center">
                                    {(index >= 0) && <label>
                                        {tt.currency} {tt.price}.-
                                    </label>}
                                </div>
                                <div className="col-md-2 col-3">
                                    <select
                                        className="form-select"
                                        onChange={(e) => onTicketTypeChange(e, tt.id)}
                                    >
                                        {Array.from(
                                            Array(
                                                tt.availability?.availableTickets + 1
                                            ),
                                            (e, i) => {
                                                return (
                                                    <option key={i} value={i}>
                                                        {i}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </div>
                            </div>
                        )}
                </div>
            ))
        );
    };
    return (
        <div className="container wrapper">
            <Header resources={resources} recommendedEventsHeader={true} />
            {!loading && <div>
                <div className="mt-4">
                    <div>
                        <div className="container">
                            {masterEventTextContainer()}
                        </div>
                        <div> {mapMasterEventTicketTypes()}</div>
                    </div>
                </div>
                <div className="mt-4">
                    <div>
                        <div className="container">
                            {recommendedWeinschiffeEventTextContainer()}
                        </div>
                        <div>{mapRecommendedWeinschiffeEventTicketTypes()}</div>
                    </div>
                    <div className="row mt-4 mb-4">
                        <div className=" col-6 text-start">
                            <button className="button" onClick={() => goBack()}>
                                {resources?.Back}
                            </button>
                        </div>
                        <div className="col-6 text-end">
                            <button className="button" onClick={() => onSubmit()}>
                                {resources?.Next}
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
            {loading &&
                <Loading bgColor="#fff" color="#eb6983" />

            }
            <Footer />
        </div>
    )
}

export default RecommendedEvents