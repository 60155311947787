import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    api, applyPromotionCode, getAddressOfOrder, getEventById, getTicketTypeById, getTicketTypeInfosByTicketTypeId,
    getTicketTypesByEventId} from "ticketino-api-client";

import Header from './components/Header'
import Footer from "./components/Footer";

const Address = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [order, setOrder] = useState({});
    const [tickets, setTickets] = useState([]);
    const [buyerInfo, setBuyerInfo] = useState({
        firstname: "",
        lastname: "",
        email: ""
    });
    const [newsletter, setNewsletter] = useState(false);

    const [promotionCode, setPromotionCode] = useState("");
    const [addedPromotions, setAddedPromotions] = useState([]);
    const [promotionError, setPromotionError] = useState("");
    const [event, setEvent] = useState({});

    const selctedCalendarEventId = sessionStorage.getItem("selctedCalendarEventId");
    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (JSON.parse(sessionStorage.getItem("AddedPromotions"))?.length > 0) {
            setAddedPromotions(JSON.parse(sessionStorage.getItem("AddedPromotions")));
        }

        if (orderId) {
            loadOrder(orderId);
            changeDeliveryTypeAndPaymentTypeToDefault();
        }
    }, [orderId]); //gets called when an order is started

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);
            const bookedTickets = order.tickets;

            const address = await getAddressOfOrder(orderId);

            setBuyerInfo({ ...buyerInfo, firstname: address.firstName, lastname: address.name, email: address.email });

            if (address.code1 === "false") {
                setNewsletter(false);
            } else {
                setNewsletter(true);
            }

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {
                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const eventId = ticketType.eventId;
                    const event = await getEventById(eventId);

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    setEvent(event);

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            if (updatedTickets.length === 0) {
                navigate(`/${language}/home`)
            }

            setOrder(order);
            setTickets(updatedTickets);
        }
        catch (error) {
            alert("1");
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const onInputChange = (e) => {
        setBuyerInfo({ ...buyerInfo, [e.target.name]: e.target.value });
    };

    const addAddressToBasket = async () => {
        let addressBody = {
            firstname: buyerInfo.firstname,
            name: buyerInfo.lastname,
            email: buyerInfo.email,
            countryId: 176,
            code1: newsletter
        };

        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then(() => navigate(`/${language}/checkout`))
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const applyPromotionCode = () => {
        axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotionCode}`).then((res) => {
            setPromotionError("");
            changeDeliveryTypeAndPaymentTypeToDefault();
            let array = addedPromotions;
            array.push(promotionCode);

            setAddedPromotions(array);
            sessionStorage.setItem("AddedPromotions", JSON.stringify(array));

            // refresh the order values after applying the promotion code
            loadOrder(orderId);
        }).catch((error) => {
            setPromotionError(resources.PromotionError);
        })
    }

    const removePromotionCode = (promotion) => {
        axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotion}`).then((res) => {
            changeDeliveryTypeAndPaymentTypeToDefault();
            setPromotionError("");

            let array = addedPromotions;
            let index = array.findIndex(promo => promo === promotion);

            array.splice(index, 1);

            setAddedPromotions(array);
            sessionStorage.setItem("AddedPromotions", JSON.stringify(array));

            // refresh the order values after applying the promotion code
            loadOrder(orderId);
        }).catch((error) => {
            setPromotionError(resources.PromotionError);
        })
    }

    const changeDeliveryTypeAndPaymentTypeToDefault = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/1`).then((res) => {
                axios
                    .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/1`).then(() => {
                        // refresh the order values
                        loadOrder(orderId);
                    }).catch((error) => {
                        console.error(error.response.data);
                    });
            }).catch((error) => {
                console.error(error.response.data);
            });
    }

    const removeTicketFromBasket = (ticketIds) => {
        let body = {
            "TicketsToRemove": ticketIds
        }

        axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { headers: { Accept: "application/json" }, data: body }).then((res) => {
            // changeDeliveryTypeAndPaymentTypeToDefault();
            loadOrder(orderId);
        }).catch((error) => {
            console.error(error.response.data);
        });
    }

    const mapTickets = () => {
        let ticketsSorted = [];

        if (tickets && tickets.length > 0) {
            ticketsSorted = [...tickets].sort((a, b) => a.id - b.id);
        }

        return (
            tickets &&
            tickets.length > 0 &&
            ticketsSorted.map((tt, index) => (
                <div key={index}>
                    <div className="row pt-2 pb-2">
                        <div className="col-md-9 col-7 p-0">
                            <label className="fs-6">
                                {tt.info?.name}
                            </label>
                        </div>
                        <div className="col-md-2 col-4 text-end p-0">
                            <label>
                                {tt.ticketType?.currency} {tt.ticketType?.price}.-
                            </label>
                        </div>
                        <div className="col-md-1 col-1 text-end pe-2">
                            <span className="cursor-pointer" onClick={() => removeTicketFromBasket([tt.id])}>
                                <i class="bi bi-x-lg"></i>
                            </span>
                        </div>
                    </div>
                </div>
            ))
        );
    }

    const textContainer = () => {
        let eventStart = "";

          if (event?.start) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            eventStart = new Date(event?.start).toLocaleDateString(`de`, options);
          }

        return (
            <div className="fs-6 text-container mb-3 row">
                <div className="col-6 text-start">{resources?.TotalPrice}</div>
                <div className="col-6 text-end fs-6">{eventStart}</div>
            </div>
        )
    }

    const goBack = async () => {
        let ticketsInOrder = [];

        let ticketTypesOfCalendarEvent = await getTicketTypesByEventId(selctedCalendarEventId);

        let calendarEventTicketTypeIds = ticketTypesOfCalendarEvent.map(tt => tt.id);

        tickets.filter(t => !calendarEventTicketTypeIds.includes(t.ticketTypeId)).map(t => ticketsInOrder.push(t.id)); 

        // deletes all the existing tickets except calendar event(main event) inside the order
        removeTicketFromBasket(ticketsInOrder);

        navigate(-1);
    }

    const onSubmit = async () => {
        await addAddressToBasket();
    };

    let validation = /^.{1,70}$/.test(buyerInfo.firstname) &&
        /^.{1,70}$/.test(buyerInfo.lastname) &&
        /^\S+@\S+\.\S+$/.test(buyerInfo.email);

    return (
        <div className="container wrapper">
            <Header />
            <div className="mt-4">
                <div>
                    <div className="container">
                        {textContainer()}
                    </div>
                    <div className="container mb-4">{mapTickets()}</div>
                </div>
                <div>
                    <div className="col-md-8 col-12 pb-3">
                        <h1 className="mb-2 fs-6 text-uppercase">
                            {resources.PromotionCode}
                        </h1>
                        <p className="m-0 fs-6 fw-light">
                            {resources.PromotionDescription}
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-12 pb-3">
                            <input
                                id="inputCode"
                                type="text"
                                name="promotionCode"
                                className="form-control input-field"
                                placeholder={resources.PromotionCode}
                                value={promotionCode}
                                onChange={(e) => setPromotionCode(e.target.value)}
                            />
                        </div>
                        <div className="col-md-4 col-3 text-center">
                            <button
                                className="text-uppercase button"
                                onClick={() => applyPromotionCode()}
                                disabled={promotionCode === ""}
                            >
                                {resources.PromotionAdd}
                            </button>
                        </div>
                    </div>
                </div>
                {promotionError != "" && <div>
                    <p className="fs-6 text-danger">{promotionError}</p>
                </div>}
                <div>
                    {addedPromotions.map((promotion, index) => (
                        <div kex={index} className="promotion p-1 mb-3 w-50">
                            <p className="d-flex justify-content-between align-items-start p-1 fs-6 mb-0">
                                {promotion}
                                <span></span>
                                <span onClick={() => removePromotionCode(promotion)}><i class="bi bi-x-lg"></i></span>
                            </p>
                        </div>
                    ))}
                </div>
                <div className="mb-4">
                    <hr className="custom-hr"></hr>
                    <div className="row">
                        <div className="col-6 fw-bold">{resources.Total}</div>
                        <div className="col-6 text-end fw-bold">{order.currency} {order.totalPrice?.toFixed(2)}</div>
                    </div>
                </div>
                <div>
                    <div className="mb-3">
                        <p className="fs-6 text-container mb-3">{resources?.BuyerInformation}</p>
                    </div>
                    <div>
                        <div className="mb-3 row">
                            <label className="col-sm-2 col-form-label text-end address-field">{resources?.BuyerFirstname}:</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control input-field"
                                    name="firstname"
                                    value={buyerInfo.firstname}
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-sm-2 col-form-label text-end address-field">{resources?.BuyerLastname}:</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control input-field"
                                    name="lastname"
                                    value={buyerInfo.lastname}
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="staticEmail" className="col-sm-2 col-form-label text-end address-field">
                                {resources?.BuyerEmail}:
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control input-field"
                                    id="staticEmail"
                                    name="email"
                                    value={buyerInfo.email}
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                        <p>{resources.Newsletter}</p>
                        <div class="form-check mb-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={newsletter}
                                id="flexCheckDefault"
                                onClick={(e) => setNewsletter(e.target.checked)}
                            />
                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                {resources.NewsletterDescription}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mb-4">
                    <div className=" col-6 text-start">
                        <button className="button" onClick={() => goBack()}>
                            {resources?.Back}
                        </button>
                    </div>
                    <div className="col-6 text-end">
                        <button className="button" disabled={!validation} onClick={() => onSubmit()}>
                            {resources?.Next}
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Address