import React from 'react'

import flyer from "../../images/Eventflyer-Bild_196x270.jpg";
import banner from "../../images/banner.jpg";
import masterclassheader from "../../images/masterclass-header.png";
import { useNavigate, useParams } from 'react-router-dom';

const Header = (props) => {

    let navigate = useNavigate();

    const { language } = useParams();

    const { masterclass, recommendedEventsHeader } = props;

    const currentPathname = window.location.pathname;

    const handleChangeLanguage = (e) => {
        const newLanguage = e.target.value;
        const newPathname = currentPathname.replace(`/${language}/`, `/${newLanguage}/`);

        navigate(newPathname);
    }

    return (
        <div>
            <div className='text-end pt-3' style={{ justifyContent: "right", display: "flex" }}>
                <div style={{ minWidth: "70px" }}>
                    <select
                        className='language-select form-select fw-semibold'
                        onChange={(e) => handleChangeLanguage(e)}
                    >
                        <option value="de" selected={language == "de"}>de</option>
                        <option value="fr" selected={language == "fr"}>fr</option>
                        <option value="it" selected={language == "it"}>it</option>
                        <option value="en" selected={language == "en"}>en</option>
                    </select>
                </div>
            </div>
            <div>
                <img className='text-center flyer' src={flyer} alt="expovina flyer"></img>
            </div>
            <div className='row'>
                <div className='col-12 fill'>
                    <img className='img-fluid' src={(masterclass == true) ? masterclassheader : banner} alt="expovina banner"></img>
                </div>
            </div>
            {(masterclass == true) ? <></> : <div className="mb-4 mt-4">
                <p className="fs-4 fw-bold">{props.resources?.Title}</p>
                {!recommendedEventsHeader && <p className="fs-5 fw-semibold">{props.resources?.SubTitle}</p>}
            </div>}
            {recommendedEventsHeader &&
                <div className="mb-4 mt-4">
                    <p className="fs-4 fw-bold recommended-events-header">{props.resources?.RecommendedEventsHeader}</p>
                </div>}
        </div>
    )
}

export default Header